@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
    background-color: #FCC71D; 
    color: #fff; 
    /* padding: 1rem; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
  }

  .navbar .nav-link:hover {
    color: #FFFFFF;
  }

  /* .navbar button:hover {
    background-color: #ea3f0a;
  } */

  /* .navbar button {
    background-color: #FF5733;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
  } */


/* .custom-scrollbar::-webkit-scrollbar {
    width: 0.5px;  
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #656262;  
    border-radius: 0px;     
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #888; 
} */


.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Custom scrollbar styling */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888f95 #f7f4f4; 
  /* 1D5C96 logo color */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

  
/* FOR PRODUCT NAME  */

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; 
  line-height: 1.5em;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/* Spinner.css */
.spinner {
  --clr: rgb(7, 7, 62);
  --gap: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

.bg-blue-100 {
  background-color: rgba(37, 99, 235, 0.1); 
}

.background-image {
  background-image: url('/src/assets/background_image.svg');
  background-size: 100%; 
  background-position: center;
  background-repeat: no-repeat; 
  height: 100%;
}

.border-red-500 {
  border-color: red;
  border-width: 1px;
  border-style: solid;
}

.selected {
  border: 2px solid blue; /* or any other styling */
  background-color: lightblue; /* or any other styling */
}

.fixed-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* set a high z-index value */
  display: flex;
  justify-content: center;
  align-items: center;
}


input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;

}

.tooltip {
  display: none; /* Hide by default */
}

.relative button:hover + .tooltip {
  display: block; /* Show tooltip when hovering over the button */
}

.ant-select-selector{
  max-height: 100px;
}
.ant-select-show-search:where(.css-dev-only-do-not-override-1yacf91).ant-select:not(.ant-select-customize-input) .ant-select-selector input{
  font-size:18px;
}

:where(.css-dev-only-do-not-override-1yacf91)[class^="ant-select"] [class^="ant-select"], :where(.css-dev-only-do-not-override-1yacf91)[class*=" ant-select"] [class^="ant-select"], :where(.css-dev-only-do-not-override-1yacf91)[class^="ant-select"] [class*=" ant-select"], :where(.css-dev-only-do-not-override-1yacf91)[class*=" ant-select"] [class*=" ant-select"]{
  font-size: large;
}

.ant-select-selection-placeholder{
  font-size:20px;
  font-weight: normal;
  color: #caccd7;
    font-family: inherit

}

.autocompleteWrapper {
  @apply flex w-full relative;
}
 
.autocompleteWrapper>div:nth-child(1) {
  width: 100%;
}
 
.autocompleteWrapper input {
  /* padding: 0 10px; */
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  width: 97%;
  height: 45px;
  border-width: 0px;
 
}
 
 
@media(max-width: 480px) {
  .autocompleteWrapper input {
    border-radius: 5px;
  border-width: 0px;
  }
}
 
.autocompleteWrapper input:focus {
  box-shadow: none;
  outline: none;
}
 