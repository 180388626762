body{
    background-color: #EEEEEE;
}

html, body {
    height: 100%;
    margin: 0; 
    padding: 0;
}

.LoginPage{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.App {
    height: 100%; 
  }
  
  #root {
    height: 100%;
  }

/* @media print {
    body * {
        visibility: hidden;
    }
    #order-receipt, #order-receipt * {
        visibility: visible;
    }
    #order-receipt {
        position: absolute;
        left: 0;
        top: 0;
        
    }
} */